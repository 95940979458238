import extend from 'extend';

export default class Postcode {
	constructor(settings = {}) {
		this._settings = {
			targets: {
				postcode: 'postcode',
				street: 'postcode-street',
				region: 'postcode-region',
				city: 'postcode-city',
				neighborhood: 'postcode-neighborhood'
			},
			url: 'https://viacep.com.br/ws/{postcode}/json/',
			useCache: true,
			isUIFieldEnabled: true
		};

		this._parent = null;
		this._postcode = null;
		this._cache = {};

		extend(true, this._settings, settings);
	}

	init() {
		this.addListeners();
	}

	addListeners() {
		document.body.addEventListener('keyup', (e) => this.handleInteraction(e));
	}

	handleInteraction(e) {
		this.validateElement(e.target)
			.then((response) => {
				this._parent = response.parent;
				this._postcode = response.postcode;

				if (response.cache !== null) return response.cache;
				else return null;
			})
			.then((response) => {
				if (response !== null) return this.fillInputs(response);
				else return this.loadData(this._postcode).then((data) => this.fillInputs(data));
			})
			.catch((message) => console.warn(message));
	}

	validateElement(target) {
		return new Promise((resolve, reject) => {
			// Validate element requirements
			if (!target.hasAttribute(this._settings.targets.postcode)) {
				// reject('Attribute does not contain required attribute "'+this._settings.targets.postcode+'"');
				return;
			}

			// Validate value length
			let value = target.value.replace(/\D/g, '');
			if (value.length !== 8) {
				// reject('Value length must be 8');
				return;
			}

			// Validate if the element is encapsuled on an form tag
			let parent = target;
			while (parent.tagName !== 'FORM') {
				parent = parent.parentNode;

				if (parent.classList === undefined) {
					parent = null;
					break;
				}
			}

			if (parent === null) {
				reject('Field must be inside an form tag');
				return;
			}

			let response = {
				parent: parent,
				postcode: value,
				cache: null
			};

			// Validate if the postcode is the same from the last request
			if (this._postcode === value) response.cache = this._cache;

			resolve(response);
		});
	}

	loadData(value) {
		if (value === undefined) throw 'Please provide a valid postcode number';

		return fetch(this.getUrl(value))
			.then((response) => response.json())
			.then((response) => this.storeCache(response));
	}

	getUrl(value) {
		return this._settings.url.replace(/\{postcode\}/g, value);
	}

	fillInputs(data = {}) {
		for (let key in data) {
			let selector = null;
			let value = null;

			switch (key) {
				// Region
				case 'uf':
					selector = this._settings.targets.region;
					value = this.getRegionName(data[key]);

					break;

				// Street
				case 'logradouro':
					selector = this._settings.targets.street;
					value = data[key];

					break;

				// City
				case 'localidade':
					selector = this._settings.targets.city;
					value = data[key];

					break;

				// Neighborhood
				case 'bairro':
					selector = this._settings.targets.neighborhood;
					value = data[key];

					break;
			}

			// Prevent reading unneeded data
			if (selector === null) continue;

			// Fill each input
			for (let target of this._parent.querySelectorAll('[' + selector + ']')) {
				if (target.tagName == 'SELECT') this.fillSelect(target, value);
				else this.fillInput(target, value);
			}
		}
	}

	fillInput(target, value) {
		if (this._settings.isUIFieldEnabled) {
			let container = target;

			while (!container.classList.contains('field')) {
				container = container.parentNode;

				if (container.classList === undefined) {
					container = null;
					break;
				}
			}

			if (container !== null) container.classList.add('filled');
		}

		target.value = value;
	}

	fillSelect(target, value) {
		for (let option of target.options) {
			if (option.innerText.trim() == value) {
				target.selectedIndex = option.index;
				const event = document.createEvent('HTMLEvents');
				event.initEvent('change', false, true);
				target.dispatchEvent(event);
				break;
			}
		}
	}

	getRegionName(regionCode = null) {
		if (regionCode === null) return null;

		let value = null;

		switch (regionCode) {
			case 'AC':
				value = 'Acre';
				break;

			case 'AL':
				value = 'Alagoas';
				break;

			case 'AP':
				value = 'Amapá';
				break;

			case 'AM':
				value = 'Amazonas';
				break;

			case 'BA':
				value = 'Bahia';
				break;

			case 'CE':
				value = 'Ceará';
				break;

			case 'DF':
				value = 'Distrito Federal';
				break;

			case 'ES':
				value = 'Espírito Santo';
				break;

			case 'GO':
				value = 'Goiás';
				break;

			case 'MA':
				value = 'Maranhão';
				break;

			case 'MT':
				value = 'Mato Grosso';
				break;

			case 'MS':
				value = 'Mato Grosso do Sul';
				break;

			case 'MG':
				value = 'Minas Gerais';
				break;

			case 'PA':
				value = 'Pará';
				break;

			case 'PB':
				value = 'Paraíba';
				break;

			case 'PR':
				value = 'Paraná';
				break;

			case 'PE':
				value = 'Pernambuco';
				break;

			case 'PI':
				value = 'Piauí';
				break;

			case 'RJ':
				value = 'Rio de Janeiro';
				break;

			case 'RN':
				value = 'Rio Grande do Norte';
				break;

			case 'RS':
				value = 'Rio Grande do Sul';
				break;

			case 'RO':
				value = 'Rondônia';
				break;

			case 'RR':
				value = 'Roraima';
				break;

			case 'SC':
				value = 'Santa Catarina';
				break;

			case 'SP':
				value = 'São Paulo';
				break;

			case 'SE':
				value = 'Sergipe';
				break;

			case 'TO':
				value = 'Tocantins';
				break;
		}

		return value;
	}

	storeCache(data = {}) {
		if (this._settings.useCache) this._cache = data;

		return data;
	}
}

window.postcode = new Postcode();
window.postcode.init();
