"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UIMask = /** @class */ (function () {
    function UIMask() {
    }
    Object.defineProperty(UIMask.prototype, "inputElements", {
        get: function () {
            var elements = document.querySelectorAll('data-input-mask');
            return elements;
        },
        enumerable: true,
        configurable: true
    });
    UIMask.prototype.applyListeners = function () {
        var _this = this;
        document.body.addEventListener('keyup', function (event) { return _this.validateElement(event); });
    };
    UIMask.prototype.validateElement = function (event) {
        var element = event.target;
        if (!element.hasAttribute('data-input-mask')) {
            return;
        }
        this.applyMask(element);
    };
    UIMask.prototype.applyMaxLength = function () {
        this.inputElements.forEach(function (element) {
            switch (element.getAttribute('data-input-mask')) {
                case 'taxvat':
                    element.setAttribute('maxlength', '18');
                    break;
                case 'phone':
                    element.setAttribute('maxlength', '17');
                    break;
                case 'postcode':
                case 'zipcode':
                case 'zip':
                    element.setAttribute('maxlength', '9');
                    break;
                case 'credit-card':
                    element.setAttribute('maxlength', '16');
                    break;
            }
        });
    };
    UIMask.prototype.applyMask = function (element) {
        var value = element.value;
        var type = element.getAttribute('data-input-mask');
        switch (type) {
            case 'taxvat':
                value = value.replace(/\D/g, '');
                if (value.length <= 11)
                    value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
                else
                    value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
                break;
            case 'phone':
                value = value.replace(/\D/g, '');
                if (value.length <= 8)
                    value = value.replace(/(\d{4})(\d{4})/g, '$1-$2');
                else if (value.length == 9)
                    value = value.replace(/(\d{5})(\d{4})/g, '$1-$2');
                else if (value.length == 10)
                    value = value.replace(/(\d{2})(\d{4})(\d{4})/g, '$1 $2-$3');
                else if (value.length == 11)
                    value = value.replace(/(\d{2})(\d{5})(\d{4})/g, '$1 $2-$3');
                else if (value.length == 12)
                    value = value.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/g, '+$1 $2 $3-$4');
                else if (value.length == 13)
                    value = value.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/g, '+$1 $2 $3-$4');
                break;
            case 'postcode':
            case 'zipcode':
            case 'zip':
                value = value.replace(/\D/g, '');
                value = value.replace(/(\d{5})(\d{3})/g, '$1-$2');
                break;
            case 'credit-card':
                value = value.replace(/\D/g, '');
                if (value.length === 13)
                    value = value.replace(/(\d{1})(\d{4})(\d{4})(\d{4})/g, '$1.$2.$3.$4');
                else if (value.length == 14)
                    value = value.replace(/(\d{4})(\d{6})(\d{4})/g, '$1.$2.$3');
                else if (value.length == 15)
                    value = value.replace(/(\d{5})(\d{4})(\d{5})(\d{1})/g, '$1.$2.$3.$4');
                else if (value.length == 16)
                    value = value.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/g, '$1.$2.$3.$4');
                break;
        }
        element.value = value;
    };
    UIMask.prototype.applyMasks = function () {
        var _this = this;
        this.inputElements.forEach(function (element) {
            _this.applyMask(element);
        });
    };
    UIMask.prototype.init = function () {
        try {
            this.applyListeners();
            this.applyMasks();
            this.applyMaxLength();
        }
        catch (error) {
            console.log(error);
        }
    };
    return UIMask;
}());
exports.default = UIMask;
var uiMask = new UIMask();
uiMask.init();
