"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var HeaderSearchToggle = /** @class */ (function () {
    function HeaderSearchToggle() {
    }
    Object.defineProperty(HeaderSearchToggle.prototype, "searchBlockElements", {
        get: function () {
            var elements = document.querySelectorAll('.search');
            return elements;
        },
        enumerable: true,
        configurable: true
    });
    HeaderSearchToggle.prototype.applyListeners = function (element) {
        var _this = this;
        element.addEventListener('click', function () {
            _this.activeSearch();
        });
        var inputElement = this.getInputElement(element);
        if (inputElement !== null) {
            inputElement.addEventListener('blur', function () {
                _this.disableSearch();
            });
        }
    };
    HeaderSearchToggle.prototype.activeSearch = function () {
        var _this = this;
        this.searchBlockElements.forEach(function (element) {
            var inputElement = _this.getInputElement(element);
            if (inputElement) {
                inputElement.focus();
            }
            element.classList.add('search--open');
        });
    };
    HeaderSearchToggle.prototype.disableSearch = function () {
        this.searchBlockElements.forEach(function (element) {
            element.classList.remove('search--open');
        });
    };
    HeaderSearchToggle.prototype.init = function () {
        var _this = this;
        this.searchBlockElements.forEach(function (element) {
            _this.applyListeners(element);
        });
    };
    HeaderSearchToggle.prototype.getInputElement = function (boxElement) {
        var element = boxElement.querySelector('input');
        return element;
    };
    return HeaderSearchToggle;
}());
exports.default = HeaderSearchToggle;
var headerSearchToggle = new HeaderSearchToggle();
headerSearchToggle.init();
